import type { ShopLoginResponse } from '@/api/instant/queries/useInstantShopLoginQuery';
import useInstantShopLoginQuery from '@/api/instant/queries/useInstantShopLoginQuery';
import useShopStore from '@/stores/shop';
import type { ConnectedType } from '@/types/connect';
import { useRoute } from 'vue-router';
import useNotification from './useNotification';
import { useShopQuery } from './useShopQuery';
import { emitUpdateShopPlan } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { getEmbedAppUrl } from '@/utils/getEmbedAppUrl';

export const useV5Authentication = (connectedType: ComputedRef<ConnectedType>, editorType: ComputedRef<string>) => {
  const route = useRoute();

  const shopStore = useShopStore();

  const { handleError } = useNotification();

  const shopIDQuery = computed(() => route.query?.shop_id?.toString());
  const shopTokenQuery = computed(() => route.query?.token?.toString());
  const shopifyDomain = computed(() => route.query?.shop?.toString());

  const isEnableShopifyAuth = computed(
    () =>
      (connectedType.value === 'editor' && editorType.value === 'gempagesv7') ||
      (connectedType.value === 'gempagesv7' && !(shopIDQuery.value && shopTokenQuery.value && shopifyDomain.value)),
  );

  useInstantShopLoginQuery(
    {
      connectedType: 'gempagesv7',
    },
    {
      enabled: isEnableShopifyAuth,
      onError: handleError,
      onSuccess: (v) => {
        handleSuccess(v);
      },
    },
  );

  const shopID = computed(() => shopStore.getShopId);
  const shopDomain = computed(() => shopStore.getShopDomain);

  const handleSuccess = (v: ShopLoginResponse) => {
    updateShopInfoInStore(v);
    if (v.shopID === '0' || !v.shopID) {
      handleError('Authentication failed, please try again later');
      backToDashboardV5();
    }
  };

  const updateShopInfoInStore = (v: ShopLoginResponse) => {
    if (!shopStore.getIsAdminLogin) {
      shopStore.setShopId(v?.shopID);
      shopStore.setToken(v?.token);
      shopStore.setShopDomain(v?.shopifyDomain);
    }
    shopStore.setUserHash(v?.userHash);
    shopStore.setFirstName(v?.firstName);
    const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
    shopStore.setStatus(!!isConnected);
    shopStore.setShopType(v.isGemPagesV7);
    shopStore.setShopPlan(v.plan);
    emitUpdateShopPlan(v?.plan);
  };

  const backToDashboardV5 = () => {
    const embedAppId = import.meta.env.VITE_GEMPAGES_APP_ID;

    if (embedAppId && shopDomain.value) {
      const url = getEmbedAppUrl(shopDomain.value) + '/dashboard';
      if (window.top) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }
    }
  };

  useShopQuery({
    shopID,
  });

  onMounted(() => {
    if (shopIDQuery.value && shopifyDomain.value && shopTokenQuery.value) {
      if (!shopStore.getIsAdminLogin) {
        shopStore.setShopId(shopIDQuery.value);
        shopStore.setToken(shopTokenQuery.value);
        shopStore.setShopDomain(shopifyDomain.value);
      }
      shopStore.setStatus(true);
    }
  });
};
